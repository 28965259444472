export const getFilterElementConnection = ({ i18n, filterState, column }) => {
  const { t } = i18n;
  const [filters, setFilters] = filterState;

  const props = {
    placeholder: t("repeatedWords.all"),
    value: filters[column]?filters[column]:null,
    onChange: (e) => {

      setFilters((prevFilters) => {
        const filterValue = e?.value || e?.target?.value
        const result = {...prevFilters}
        
        if(filterValue){
          result [column] = filterValue
        }else{
          delete result[column]
        }

        return result
      });
    },
  };

  return props;
};
