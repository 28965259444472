import "./style.css";

export const PageHeader = ({ title = "Page header title", Icon, children }) => (
  <header className="page_header">
    <div className="page_header_title_container">
    <h2 className="page_header_title">{Icon?<Icon />:<></>}  {title}</h2>
    {children}
    </div>
    <hr className="spacer" />
  </header>
);
