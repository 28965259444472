
import { useState,useEffect } from "react";
import { ActionEmployee as UI } from "./ui/ActionEmployee";
import { api } from "../../../../../shared/api";
import { toastMessageConfig } from "../../../../../entities/toastMessageConfig";
import { itemsPerPageForAllItems } from "../../../../../entities/itemsPerPageForAllItems";
import { useForm } from "react-hook-form";
import { initValues } from "./lib/"

export const ActionEmployee = ({i18n,tableState,departments,companies,toastRef,dialogState,defaultValues = [],actionType = "add"}) => {

  const [componentStatus, setComponentStatus] = useState({ status: "loading" });

  const { t } = i18n

  const [,setDialogStateValue] = dialogState;

  const formState = useForm({ mode: "onChange",defaultValues: {...initValues, ...defaultValues}})

  useEffect(() => {
    (async () => {

      const usersResponse = await api.users.get({itemsPerPage:itemsPerPageForAllItems});

      const { warning, error } = usersResponse;

      if(warning){
        toastRef.current.show({ severity: 'warn', summary: warning.title, detail: warning.message,life:toastMessageConfig.life})
        setDialogStateValue({visibility:false})
        return
      }
  
      if(error){
        toastRef.current.show({ severity: 'error', summary: error.title, detail: error.message,sticky:true })
        setDialogStateValue({visibility:false})
        return
      }

      setComponentStatus({status:"waiting",data:{usersResponse}})

    })();
  }, []);


  const onSubmit = async (fData) => {
    
    setComponentStatus({ status: "loading" });

    const additionalDepartmentsFieldValueCopy = fData.additionalDepartments
    delete fData.additionalDepartments

    const postEmployeeResponse = await (actionType === "add"?api.employees.post(fData):api.employees.update(fData.id, fData))

    setDialogStateValue({visibility:false})

    const {warning,error} = postEmployeeResponse

    if(warning){
      toastRef.current.show({ severity: 'warn', summary: warning.title, detail: warning.message,life:toastMessageConfig.life})
      return
    }

    if(error){
      toastRef.current.show({ severity: 'error', summary: error.title, detail: error.message,sticky:true })
      return
    }

    if(additionalDepartmentsFieldValueCopy.length){
      const requestBody = {iri:postEmployeeResponse["@id"],departments:additionalDepartmentsFieldValueCopy}
      const postAdditionalDepartmentsResponse = (actionType === "add"?api.employees.postAdditionalDepartments(requestBody):api.employees.putAdditionalDepartments(requestBody))

      if(postAdditionalDepartmentsResponse?.warning){
        const { warning } = postAdditionalDepartmentsResponse
        toastRef.current.show({ severity: 'warn', summary: warning.title, detail: warning.message,life:toastMessageConfig.life})
        return
      }

      if(postAdditionalDepartmentsResponse?.error){
        const { error } = postAdditionalDepartmentsResponse
        toastRef.current.show({ severity: 'error', summary: error.title, detail: error.message,sticky:true })
        return
      }
    }

    const [,setTableStateValue] = tableState
    toastRef.current.show({ severity: 'success', summary: t("repeatedWords.success"), detail: t("message.successfulOperation.message"),life:toastMessageConfig.life})
    setTableStateValue(prevValue=>({...prevValue,refresh:true}))
  };


  if(componentStatus.status === "loading") return <UI i18n={i18n} formState={formState} actionType={actionType} departments={departments} companies={companies} loading />;
  if(componentStatus.status === "waiting"){
    const users = componentStatus.data.usersResponse["hydra:member"]
     return <UI i18n={i18n} formState={formState} actionType={actionType} users={users} onSubmit={onSubmit} departments={departments} companies={companies}/>;
  }

};