import { disabledTypesIriForUserArr } from "./lib";

export const getTypeOptions = (electedEmployee,currentUser) => {
  let options = [];

  if (electedEmployee && electedEmployee?.vacationLimits?.length) {
    for (const vacationLimit of electedEmployee.vacationLimits) {
      let continueTrigger = false;

      if (
        currentUser.roles.includes("ROLE_USER") &&
        !currentUser.roles.includes("ROLE_ADMIN") &&
        !currentUser.roles.includes("ROLE_KADR") &&
        !currentUser.roles.includes("ROLE_MOD")
      ) {
        for (const disabledIri of disabledTypesIriForUserArr) {
          if (vacationLimit.vacationType["@id"] === disabledIri) {
            continueTrigger = true;
            break;
          }
        }
      }

      if (continueTrigger) continue;
      options.push(vacationLimit.vacationType);
    }

    return options;
  }
};
