export const Vacation = ({secondClassName}) => (
  <svg
  className={`icon${secondClassName?" " + secondClassName: ""}`}
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Layer_Grid" />

    <g id="Layer_2">
      <path d="M2.9,9C3,9,3.1,9,3.2,9c0.2,0,0.4-0.1,0.6-0.2l0.4-0.3C4.1,8.8,3.9,9,3.8,9.3c0,0,0,0,0,0c-0.4,0.8-0.6,1.7-0.7,2.6   c0,0,0,0,0,0c0,0,0,0,0,0C3,12.3,3,12.6,3,13c0,5,4,9,9,9s9-4,9-9c0-0.4,0-0.7-0.1-1.1c-0.2-1.2-0.5-2.4-1.1-3.4   C19.6,8.2,19.3,8,18.9,8h-3.5l1.2-2.8L19.8,6c0.5,0.1,1.1-0.2,1.2-0.7c0.1-0.5-0.2-1.1-0.7-1.2l-4-1c-0.5-0.1-1,0.1-1.2,0.6L13.2,8   H8.4L6.9,6.5l1.5-1.1c0.3-0.2,0.4-0.5,0.4-0.8c0-0.3-0.1-0.6-0.4-0.8C7.7,3.3,6.9,3,6,3C3.8,3,2,4.8,2,6.9c0,0.5,0.1,1,0.3,1.5   C2.4,8.7,2.6,8.9,2.9,9z M19,13c0,3.9-3.1,7-7,7s-7-3.1-7-7H19z M18.3,10c0.2,0.3,0.3,0.7,0.4,1H5.3c0.1-0.3,0.2-0.7,0.4-1H18.3z    M5.1,8C5,8,4.9,8,4.9,8l0.4-0.3L5.6,8H5.1z M5.5,5.1L4.2,6C4.5,5.5,5,5.2,5.5,5.1z" />
    </g>
  </svg>
);
