export const getFilterDefaultValues = (filterProp) => {

    let values = {unActive:false}
    
    if(filterProp){values = {...values,...filterProp}}

    return values
}


