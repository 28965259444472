export const getMonthsArray = (i18n,monthFormat = "short") =>{

    const months = []

    const dateFormat = new Intl.DateTimeFormat(i18n.resolvedLanguage, {month: monthFormat,});
    
    const dateForMonths = new Date();
    
    for (let monthId = 0; monthId < 12; monthId++) {
        dateForMonths.setMonth(monthId);
        const monthName = dateFormat.formatToParts(dateForMonths).find((part) => part.type === "month").value;
        months.push(monthName);
    }

    return months
}