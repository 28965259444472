import { useForm, Controller } from "react-hook-form";
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from "primereact/button";
import { FieldError } from "../../../../shared/FieldError";
import { loadingProps } from "../../../../entities/loadingProps"

export const LogIn = ({ i18n, onSubmit , loading}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ mode: "onChange",defaultValues:{email:"",password:""} });

  
  const { t } = i18n;							

  return (
    <form onSubmit={handleSubmit((fData)=>onSubmit(fData))}>
      <div className="form_fields_grid">
        <label className="align_self_center">Email *</label>
        <div className="form_input_container">
          <Controller name="email" control={control} rules={{required:true}} render={({field})=><InputText maxLength={50} placeholder={`${t("repeatedWords.enter")} Email`} value={field.value} onChange={(e) => field.onChange(e.target.value)} />}/>
          <FieldError fieldErr={errors?.email}/>
        </div>

        <label className="align_self_center">{t("pages.logIn.form.fields.password.label")} *</label>
        <div className="form_input_container">
          <Controller name="password" control={control} rules={{required:true}} render={({field})=><Password maxLength={50} placeholder={`${t("repeatedWords.enter")} ${t("pages.logIn.form.fields.password.label")}`} value={field.value} onChange={(e) => field.onChange(e.target.value)} feedback={false} toggleMask />}/>
          <FieldError fieldErr={errors?.password}/>
        </div>

        <Button className="submit_button" label={t("pages.logIn.header.title")} {...(loading?loadingProps:{})}/>
      </div>
    </form>
  );
};
