import { toastMessageConfig } from "../../../../entities/toastMessageConfig";
import { api } from "../../../api";
import { getPersonsOptionLabel } from "../../../lib";

export const loadMore = async (i18n, filterDefaultValues, toastRef, states) => {
  const { componentState, optionsState, pageState } = states;

  const [componentStateValue, setComponentStateValue] = componentState;
  const [optionsStateValue, setOptionsStateValue] = optionsState;
  const [pageStateValue, setPageStateValue] = pageState;

  setComponentStateValue({ status: "loading" });
  const employeesResponse = await api.employees.get({
    ...filterDefaultValues,
    page: pageStateValue.page,
  });

  const { error, warning } = employeesResponse;

  if (warning) {
    toastRef.current.show({
      severity: "warn",
      summary: warning.title,
      detail: warning.message,
      life: toastMessageConfig.life,
    });
    return;
  }

  if (error) {
    toastRef.current.show({
      severity: "error",
      summary: error.title,
      detail: error.message,
      sticky: true,
    });
    return;
  }

  setComponentStateValue({ status: "success" });

  const employees = employeesResponse["hydra:member"];
  getPersonsOptionLabel(employees);

  setOptionsStateValue((prevState) => {
    const {mainView:mainViewOptions, selected:selectedGroup} =  prevState

    const [department, all] = mainViewOptions;

    const newMainViewOptions = [department, { label:all.label,items:[...all.items,...employees] }];

    if(prevState?.selected){
      return { ...prevState,mainView: newMainViewOptions, options:[selectedGroup,...newMainViewOptions] };
    }
     
    return { mainView: newMainViewOptions, options: newMainViewOptions };
  });
};
