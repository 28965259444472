import { useState } from "react";
import { LogIn as UI } from "./ui/LogIn";
import { useNavigate } from 'react-router-dom';
import { api } from "../../../shared/api";
import { toastMessageConfig } from "../../../entities/toastMessageConfig";
import { initialProjectDirectory } from "../../../entities/initialProjectDirectory"
import { authTokenName } from "../../../entities/authTokenName"

export const LogIn = ({ i18n, toastRef,currentUserState}) => {
  const navigate = useNavigate();

  const [componentStatus, setComponentStatus] = useState({ status: "waiting" });

  const getOnSubmit = (i18n,toastRef,navigate,currentUserState)=> async (fData) =>{

    const [currentUser,setCurrentUser] = currentUserState

    const { t } = i18n;	

    setComponentStatus({ status: "loading" });

    const logInResponse = await api.auth.logIn(fData)

    const {warning,error} = logInResponse

    if(warning){
        toastRef.current.show({
                    severity: "warn",
                    summary: t("repeatedWords.error"),
                    detail: t("error.user.passwordOrLogin.message"),
                  });
      setComponentStatus({ status: "waiting" });
      return
    }

    if(error){
      toastRef.current.show({ severity: 'error', summary: error.title, detail: error.message,sticky:true })
      setComponentStatus({ status: "waiting" });
      return
    }

    toastRef.current.show({ severity: 'success', summary: t("repeatedWords.success"), detail: t("message.successfulOperation.message"),life:toastMessageConfig.life})
    
    sessionStorage.setItem(authTokenName, `Bearer ${logInResponse.token}`)
    setCurrentUser(undefined)
    navigate(`${initialProjectDirectory}/home`)
  }

    return <UI i18n={i18n} onSubmit={getOnSubmit(i18n,toastRef,navigate,currentUserState)} {...(componentStatus.status === "loading"?{loading:true}:{})}/>;
};
