import { WebSiteIcon } from "./svg/WebSiteIcon";
import { PhoneCallIcon } from "./svg/PhoneCallIcon";
import "./style.css";

export const PageFooter = () => (
  <footer className="page_footer">
    <div className="page_footer_link_container">
      <a
        href="tel:+48585850000"
        className="page_footer_link"
        target="_blank"
        rel="noreferrer"
      >
        <PhoneCallIcon className={"icon"} />
        <span>+48 58 585 0000</span>
      </a>
      <a
        href="https://beupsoft.pl/"
        className="page_footer_link"
        target="_blank"
        rel="noreferrer"
      >
        <WebSiteIcon className={"icon"} />
        <span>https://beupsoft.pl</span>
      </a>
    </div>
    <span>© 2023 </span>
  </footer>
);
