import { Link } from "react-router-dom";
import { Menu as PsMenu, MenuItem, SubMenu } from "react-pro-sidebar";
import "./style.css";
import { icons } from "./icons";
import { useLocation } from "react-router-dom";
import { isActive,isSubMenuOpen } from "../lib";
import { initialProjectDirectory } from "../../../entities/initialProjectDirectory";

export const Menu = ({i18n}) => {
  const location = useLocation();
  const currentPath = location.pathname;

  const { t } = i18n ;

  return (
    <PsMenu className="mainMenu">
      <MenuItem component={<Link to={`${initialProjectDirectory}/home`} />} icon={<icons.Home />}  {...isActive.item(currentPath,"/view/home")}>Home</MenuItem>

      <SubMenu label={t("menu.subMenuVacations.title")} icon={<icons.Vacation />} {...isActive.sub(currentPath,"/view/vacations")} {...isSubMenuOpen(currentPath,"/view/vacations")}>
        <MenuItem component={<Link to={`${initialProjectDirectory}/vacations/createRequest`}/>} {...isActive.item(currentPath,`${initialProjectDirectory}/vacations/createRequest`)}>{t("menu.subMenuVacations.menuItemCreateRequest")}</MenuItem>
        <MenuItem component={<Link to={`${initialProjectDirectory}/vacations/calendar`}></Link>} {...isActive.item(currentPath,`${initialProjectDirectory}/vacations/calendar`)}>{t("menu.subMenuVacations.menuItemCalendar")}</MenuItem>
        <MenuItem component={<Link to={`${initialProjectDirectory}/vacations/listOfRequests`}/>} {...isActive.item(currentPath,`${initialProjectDirectory}/vacations/listOfRequests`)}>{t("menu.subMenuVacations.menuItemRequestsList")}</MenuItem>
        {/* <MenuItem component={<Link to="/vacations/reports"></Link>}  {...isActive.item(currentPath,"/vacations/reports")}>{t("menu.subMenuVacations.menuItemReports")}</MenuItem> */}
      </SubMenu>
      {/* <SubMenu label={t("menu.subMenuDelegations.title")} icon={<icons.Delegations />}>
        <MenuItem component={<Link></Link>}>{t("menu.subMenuDelegations.menuItemCreateBusinessTrip")}</MenuItem>
        <MenuItem component={<Link></Link>}>{t("menu.subMenuDelegations.menuItemTripsHistory")}</MenuItem>
        <MenuItem component={<Link></Link>}>{t("menu.subMenuDelegations.menuItemAddBilling")}</MenuItem>
        <MenuItem component={<Link></Link>}>{t("menu.subMenuDelegations.menuItemReports")}</MenuItem>
        <MenuItem component={<Link></Link>}>{t("menu.subMenuDelegations.menuItemDelegationSettlements")}</MenuItem>
      </SubMenu> */}
    </PsMenu>
  );
};
