import { useEffect, useMemo, useState } from "react"
import { ActionVacationRequest as UI } from "./ui/ActionVacationRequest"
import { getElectedEmployeeUseEffectFunc, getPlannedVacationDatesArr, initValues } from "./lib"
import { api, getDateToSend } from "../../../shared/api"
import { toastMessageConfig } from "../../../entities/toastMessageConfig"
import { removeEmptyKeys } from "../../../shared/api/lib/removeEmptyKeys"
import { useForm } from "react-hook-form"
import { useRef } from "react"
import { getDateObjectsArr } from "../../../shared/lib"

export const ActionVacationRequest = ({
  i18n,
  currentUser,
  className,
  type = "new",
  toastRef,
  holidayDates,
  defaultValues,
  electedEmployeeProp,
  dialogState,
  tableState,
}) => {
  const componentState = useState({ status: "waiting" })
  const [componentStateValue, setComponentStateValue] = componentState

  const isInitialRender = useRef(true)

  const defaultValuesFormCopy = useMemo(() => (defaultValues ? { ...defaultValues } : undefined), [])
  if (defaultValuesFormCopy) getPlannedVacationDatesArr(defaultValuesFormCopy)

  const formState = useForm({ mode: "onChange", defaultValues: defaultValuesFormCopy || initValues })
  const { watch } = formState

  const electedEmployeeIri = watch("employee")

  useEffect(() => {
    if (!isInitialRender.current && electedEmployeeIri) {
      getElectedEmployeeUseEffectFunc(toastRef, componentState, electedEmployeeIri)
    } else isInitialRender.current = false
  }, [electedEmployeeIri])

  const { t } = i18n

  const onSubmit = async (fData) => {
    setComponentStateValue((prevState) => ({ ...prevState, status: "loading" }))

    const { plannedVacationDatesArr } = fData

    fData.dateFrom = getDateToSend(new Date(plannedVacationDatesArr[0]))
    fData.dateTo = getDateToSend(new Date(plannedVacationDatesArr[1]))

    delete fData.plannedVacationDatesArr

    removeEmptyKeys(fData)

    const vacationActionResponse = await (type === "new"
      ? api.vacations.post(fData)
      : api.vacations.put(fData?.id, fData))

    if (type === "update") {
      const [, setInfoDialogStateValue] = dialogState
      const [, setTableStateValue] = tableState

      setInfoDialogStateValue({ visible: false })
      setTableStateValue((prevState) => ({ ...prevState, refreshesNumber: prevState.refreshesNumber + 1 }))
    }

    const { warning, error } = vacationActionResponse

    if (warning) {
      toastRef.current.show({
        severity: "warn",
        summary: warning.title,
        detail: warning.message,
        life: toastMessageConfig.life,
      })
      setComponentStateValue((prevState) => ({ ...prevState, status: "waiting" }))

      return
    }

    if (error) {
      toastRef.current.show({
        severity: "error",
        summary: error.title,
        detail: error.message,
        sticky: true,
      })
      setComponentStateValue((prevState) => ({ ...prevState, status: "waiting" }))
      return
    }

    toastRef.current.show({
      severity: "success",
      summary: t("repeatedWords.success"),
      detail: t("message.successfulOperation.message"),
      life: toastMessageConfig.life,
    })

    setComponentStateValue((prevState) => ({ ...prevState, status: "waiting" }))
  }

  if (componentStateValue.status === "loading")
    return (
      <UI
        formState={formState}
        currentUser={currentUser}
        i18n={i18n}
        toastRef={toastRef}
        className={className}
        type={type}
        onSubmit={onSubmit}
        holidayDates={holidayDates}
        loading
      />
    )

  if (componentStateValue.status === "waiting")
    return (
      <UI
        formState={formState}
        currentUser={currentUser}
        i18n={i18n}
        toastRef={toastRef}
        className={className}
        type={type}
        onSubmit={onSubmit}
        holidayDates={holidayDates}
        electedEmployee={componentStateValue?.data?.electedEmployee || electedEmployeeProp}
      />
    )
}
