import { CloseIcon } from "../../lib/img/svg";
import "./style.css";

export const AbsolutePlatform = ({
  children,
  maxWidth,
  closeOnClick = false,
  backdrop = false,
  secondClassName = "container_local",
}) => {
  return (
    <>
      <div
        className={`absolute_platform ${secondClassName || " "}`}
        style={{ width: "100%", maxWidth }}
      >
        {closeOnClick && (
          <CloseIcon
            secondClassName="absolutePlatform_closeIcon"
            onClick={closeOnClick}
          />
        )}
        {children}
      </div>
      {backdrop && <div className="absolutePlatform_backdrop"></div>}
    </>
  );
};
