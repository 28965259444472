import { mainPath } from "../../lib/mainPath";
import { authTokenName } from "../../../../entities/authTokenName";

export const getOptimaExtractURLString = (companyId,params) =>{
  const url = new URL(`${mainPath.replace('/api', '')}/vacations/extract/${String(companyId)}`)

  url.searchParams.append("auth",sessionStorage.getItem(authTokenName).replace('Bearer ', ''))
  
  if(params) for (const key in params) url.searchParams.append(key, params[key]);
  
 return url.toString()
}
