import { SyncLoader } from "react-spinners";
import "./style.css";

export const Loading = ({ backdrop, color, secondClassName }) => {
  return (
    <>
      {backdrop ? <div className="backdrop"></div> : <></>}
      <SyncLoader
        {...(color ? { color } : { color: "var(--beUpColorLight)" })}
        {...(secondClassName
          ? { className: "loader " + secondClassName }
          : { className: "loader" })}
      />
    </>
  );
};
