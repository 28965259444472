export const primeReactPlLocale = {
  pl: {
    startsWith: "Zaczyna się od",
    contains: "Zawiera",
    notContains: "Nie zawiera",
    endsWith: "Kończy się na",
    equals: "Równe",
    notEquals: "Nierówne",
    noFilter: "Wyczyść filtr",
    lt: "Mniejsze od",
    lte: "Mniejsze lub równe",
    gt: "Większe od",
    gte: "Większe lub równe",
    dateIs: "Równa",
    dateIsNot: "Nierówna",
    dateBefore: "Przed",
    dateAfter: "Po",
    clear: "✘",
    apply: "✔",
    matchAll: "Dopasuj wszystko",
    matchAny: "Dopasuj dowolne",
    addRule: "Dodaj regułę",
    removeRule: "Usuń regułę",
    accept: "Tak",
    reject: "Nie",
    choose: "Wybierz",
    upload: "Wgraj",
    cancel: "Anuluj",
    completed: "Ukończone",
    pending: "Oczekuje",
    dayNames: [
      "Niedziela",
      "Poniedziałek",
      "Wtorek",
      "Środa",
      "Czwartek",
      "Piątek",
      "Sobota",
    ],
    dayNamesShort: ["Ndz", "Pon", "Wt", "Śr", "Czw", "Pt", "Sob"],
    dayNamesMin: ["Nd", "Pn", "Wt", "Śr", "Cz", "Pt", "So"],
    monthNames: [
      "Styczeń",
      "Luty",
      "Marzec",
      "Kwiecień",
      "Maj",
      "Czerwiec",
      "Lipiec",
      "Sierpień",
      "Wrzesień",
      "Październik",
      "Listopad",
      "Grudzień",
    ],
    monthNamesShort: [
      "Sty",
      "Lut",
      "Mar",
      "Kwi",
      "Maj",
      "Cze",
      "Lip",
      "Sie",
      "Wrz",
      "Paź",
      "Lis",
      "Gru",
    ],
    quarterNames: ["I Kwartał", "II Kwartał", "III Kwartał", "IV Kwartał"],
    quarterNamesShort: ["I Kw", "II Kw", "III Kw", "IV Kw"],
    chooseYear: "Wybierz rok",
    chooseMonth: "Wybierz miesiąc",
    chooseDate: "Wybierz datę",
    prevDecade: "Poprzednia dekada",
    nextDecade: "Następna dekada",
    prevYear: "Poprzedni rok",
    nextYear: "Następny rok",
    prevMonth: "Poprzedni miesiąc",
    nextMonth: "Następny miesiąc",
    prevHour: "Poprzednia godzina",
    nextHour: "Następna godzina",
    prevMinute: "Poprzednia minuta",
    nextMinute: "Następna minuta",
    prevSecond: "Poprzednia sekunda",
    nextSecond: "Następna sekunda",
    today: "Dzisiaj",
    weekHeader: "Tydzień",
    firstDayOfWeek: 1,
    showMonthAfterYear: false,
    dateFormat: "dd.mm.yy",
    weak: "Słaby",
    medium: "Średni",
    strong: "Silny",
    passwordPrompt: "Wprowadź hasło",
    emptyFilterMessage: "Brak wyników wyszukiwania",
    searchMessage: "Dostępnych jest {0} wyników",
    selectionMessage: "Wybrano {0} elementów",
    emptySelectionMessage: "Brak wybranego elementu",
    emptySearchMessage: "Nie znaleziono wyników",
    emptyMessage: "Brak danych",
    aria: {
      trueLabel: "Prawda",
      falseLabel: "Fałsz",
      nullLabel: "Nie zaznaczone",
      star: "1 gwiazdka",
      stars: "{star} gwiazdek",
      selectAll: "Wszystkie elementy zaznaczone",
      unselectAll: "Wszystkie elementy odznaczone",
      close: "Zamknij",
      previous: "Poprzedni",
      next: "Następny",
      navigation: "Nawigacja",
      scrollTop: "Przewiń do góry",
      moveTop: "Przesuń do góry",
      moveUp: "Podnieść",
      moveDown: "Położyć",
      moveBottom: "Przesuń w dół",
      moveToTarget: "Przenieś do celu",
      moveToSource: "Przenieś do źródła",
      moveAllToTarget: "Przenieś wszystko do celu",
      moveAllToSource: "Przenieś wszystko do źródła",
      pageLabel: "{page}",
      firstPageLabel: "Pierwsza strona",
      lastPageLabel: "Ostatnia strona",
      nextPageLabel: "Następna strona",
      previousPageLabel: "Poprzednia strona",
      rowsPerPageLabel: "Wierszy na stronę",
      selectRow: "Wiersz zaznaczony",
      unselectRow: "Wiersz odznaczony",
      expandRow: "Wiersz rozwinięty",
      collapseRow: "Zwinięty wiersz",
      showFilterMenu: "Pokaż menu filtrów",
      hideFilterMenu: "Ukryj menu filtrów",
      filterOperator: "Operator filtra",
      filterConstraint: "Ograniczenie filtra",
      editRow: "Edycja wiersza",
      saveEdit: "Zapisz edycję",
      cancelEdit: "Anuluj edycję",
      listView: "Widok listy",
      gridView: "Widok siatki",
      slide: "Slajd",
      slideNumber: "{slideNumber}",
      zoomImage: "Powiększ obraz",
      zoomIn: "Przybliż",
      zoomOut: "Oddal",
      rotateRight: "Obróć w prawo",
      rotateLeft: "Obróć w lewo",
    },
  },
};
