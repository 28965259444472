export const getOptionsGroup = (id,options) =>{

    for (const key in options) {
       const group = options[key]

       if(group?.id === id)return {key,group}
    }

    return undefined

}