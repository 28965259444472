import { Controller } from "react-hook-form";
import { Button } from "primereact/button";
import { FieldError } from "../../../../shared/FieldError";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from 'primereact/calendar';

export const ExportOptima = ({formState, i18n, onSubmit ,companies, loading}) => {
  
  const { handleSubmit, control, formState: { errors } } = formState;
  const { t } = i18n;							

  return (
    <form onSubmit={handleSubmit((fData)=>onSubmit(fData))}>
      <div className="form_fields_grid">
        
        <label className="align_self_center">{t("repeatedWords.company")} *</label>
        <div className="form_input_container">
        <Controller name="company" control={control} rules={{required:true}} render={({field})=><Dropdown placeholder={t("repeatedSentences.placeholder.company")} optionLabel={"name"} optionValue={"id"} options={companies} value={field.value} onChange={(e) => field.onChange(e.value)}/> }/>
        <FieldError fieldErr={errors?.company}/>
        </div>

        <label className="align_self_center">{t("repeatedSentences.dateRange")} *</label>
        <div className="form_input_container">
        <Controller name="dateRange" control={control} rules={{required:true}} render={({field})=>
          <Calendar value={field.value} onChange={(e) => field.onChange(e.value)} selectionMode="range" readOnlyInput showIcon showButtonBar placeholder={`${t("repeatedWords.choose")} ${t("repeatedSentences.dateRange")}`}/>}
        />
        <FieldError fieldErr={errors?.dateRange}/>
        </div>

        <Button className="submit_button" label={t("repeatedWords.export")} {...(loading?{loading}:{})}/>
      </div>
    </form>
  );
};