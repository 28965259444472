export const arrowMenuButton = (toggled,sideBarRef, toggledStateValue) => {
  if (toggled && sideBarRef?.current && toggledStateValue) {
    const buttonMargin = -40

    return {
      button: {style:{ left: sideBarRef?.current.offsetWidth + buttonMargin }}, icon:{style:{transform: "rotate(180deg)"}},
    };
  }

  return {button:{},icon:{}};
};
