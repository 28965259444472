import axios from "axios";
import { parseError } from "./lib/";
import { authTokenName } from "../../../../entities/authTokenName";

export const put = async (mainPath, path, body, headers = {}, defaultHeadersParam = true) => {
  
  const defaultHeaders = {Authorization:sessionStorage.getItem(authTokenName), Accept:"application/ld+json"}
  const resultHeaders = defaultHeadersParam ? {...headers,...defaultHeaders}:headers

  try {
    const requestResponse = await axios.put(mainPath + path, body, {
      headers: resultHeaders,
    });

    return requestResponse.data;
  } catch (err) {
    return parseError(err);
  }
};
