import { Controller } from "react-hook-form"
import { Button } from "primereact/button"
import { Calendar } from "primereact/calendar"
import { FieldError } from "../../../../../../shared/FieldError"
import { InputText } from "primereact/inputtext"

export const ActionHolidayDate = ({ formState, i18n, onSubmit, loading }) => {
  const { t } = i18n

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = formState

  return (
    <form onSubmit={handleSubmit((fData) => onSubmit(fData))}>
      <div className="form_fields_grid">
        <label className="align_self_center">{t("repeatedWords.description")} *</label>
        <div className="form_input_container">
          <Controller
            name="name"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <InputText
                maxLength={50}
                placeholder={t("repeatedSentences.placeholder.description")}
                value={field.value}
                onChange={(e) => field.onChange(e.target.value)}
              />
            )}
          />
          <FieldError fieldErr={errors?.name} />
        </div>

        <label className="align_self_center">{t("repeatedWords.date")} *</label>
        <div className="form_input_container">
          <Controller
            name="date"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Calendar
                value={field.value}
                onChange={(e) => field.onChange(e.value)}
                readOnlyInput
                showIcon
                showButtonBar
                placeholder={t("repeatedSentences.placeholder.date")}
              />
            )}
          />
          <FieldError fieldErr={errors?.date} />
        </div>

        <Button className="submit_button" label={t("repeatedWords.add")} {...(loading ? { loading } : {})} />
      </div>
    </form>
  )
}
