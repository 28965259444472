import { EnglandFlag, PolandFlag } from "../img";

export const countryOptionTemplate = (option) => {
  return (
    <div className="languageSwitcher_option">
      {option.value === "pl" ? <PolandFlag /> : <></>}
      {option.value === "en" ? <EnglandFlag /> : <></>}
      <div className="languageSwitcher_option_title">{option.label}</div>
    </div>
  );
};
