import { vacationRequestStatusesIris } from "../../../../../entities/vacationRequestStatusesIris"

export const getFieldsConfigByAccess = ({ actionType, currentUser, defaultValues }) => {
  const config = {}

  switch (actionType) {
    case "new": {
      if (currentUser.roles.includes("ROLE_ADMIN")) {
      } else if (currentUser.roles.includes("ROLE_MOD")) {
      } else if (currentUser.roles.includes("ROLE_KADR")) {
      } else {
        config.employee = { value: currentUser.employee["@id"], disabled: true }
      }
      break
    }

    case "update": {
      if (currentUser.roles.includes("ROLE_ADMIN")) {
        config.employee = { disabled: true }
        config.type = { disabled: true }
        config.plannedVacationDatesArr = { disabled: true }
        config.comment = { disabled: true }

        const { status: vacationRequestStatus } = defaultValues

        switch (vacationRequestStatus) {
          case vacationRequestStatusesIris.planned: {
            config.type = { disabled: false }
            config.comment = { disabled: false }
          }
          case vacationRequestStatusesIris.waiting: {
            config.type = { disabled: false }
            config.comment = { disabled: false }
          }

          case vacationRequestStatusesIris.accepted: {
            config.type = { disabled: false }
            config.comment = { disabled: false }
          }

          case vacationRequestStatusesIris.canceled: {
            config.comment = { disabled: false }
          }
        }
      } else if (currentUser.roles.includes("ROLE_MOD")) {
        config.employee = { disabled: true }
        config.type = { disabled: true }
        config.plannedVacationDatesArr = { disabled: true }
        config.comment = { disabled: true }

        const { status: vacationRequestStatus } = defaultValues

        switch (vacationRequestStatus) {
          case vacationRequestStatusesIris.planned: {
            if (currentUser.employee["@id"] === defaultValues.employee) {
              config.type = { disabled: false }
            }
          }

          case vacationRequestStatusesIris.waiting: {
            config.comment = { disabled: false }
          }

          case vacationRequestStatusesIris.accepted: {
            config.type = { disabled: false }
            config.comment = { disabled: false }
          }

          case vacationRequestStatusesIris.canceled: {
            config.comment = { disabled: false }
          }
        }
      } else if (currentUser.roles.includes("ROLE_KADR")) {
        config.employee = { disabled: true }
        config.type = { disabled: true }
        config.plannedVacationDatesArr = { disabled: true }
        config.comment = { disabled: true }

        if (currentUser.employee["@id"] === defaultValues.employee) {
          const { status: vacationRequestStatus } = defaultValues

          switch (vacationRequestStatus) {
            case vacationRequestStatusesIris.planned: {
              config.type = { disabled: false }
            }
          }
        }
      } else {
        config.employee = { disabled: true }
        config.type = { disabled: true }
        config.comment = { disabled: true }
        config.plannedVacationDatesArr = { disabled: true }

        const { status: vacationRequestStatus } = defaultValues

        switch (vacationRequestStatus) {
          case vacationRequestStatusesIris.planned: {
            config.type = { disabled: false }
            config.comment = { disabled: false }
          }
          case vacationRequestStatusesIris.waiting: {
            config.plannedVacationDatesArr = { disabled: false }
          }
        }
      }
      break
    }
  }

  return config
}
