import { Sidebar } from "react-pro-sidebar";
import { Menu } from "../../../shared/Menu";
import logo from "../../../shared/lib/img/Logo_BeUpHR.png";
import shortLogo from "../../../shared/lib/img/short_Logo_BeUpHR.png";
import "./style.css";
import { LanguageSwitcher } from "../../../shared/LanguageSwitcher/ui/LanguageSwitcher";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import { Menu as SettingsMenu } from "primereact/menu";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { initialProjectDirectory } from "../../../entities/initialProjectDirectory";
import { checkDisplay} from "../../../shared/lib";
import { useMemo } from "react";
import { getSettingsMenuItems,getArrowMenuButtonStyleProps,getToggledProps } from "../lib";
import { PageFooter } from "../../../shared/PageFooter";

export const SideBar = ({navigate,location,currentUser}) => {
  const { i18n } = useTranslation();
  
  const sideBarRef = useRef(null)

  const collapsed = useMemo(()=>(checkDisplay.width("tablet") || checkDisplay.width("laptop"))?true:false,[])

  let toggled = false;

  if(!collapsed && checkDisplay.width("phone")) toggled = true

  const toggledState = useState(false)
  const [toggledSateValue, setToggledStateValue] = toggledState;

  const settingsMenuItems = useMemo(()=>getSettingsMenuItems(currentUser,i18n,navigate),[currentUser])
  const settingsMenuRef = useRef(null);

  const arrowMenuButtonStyleProps = useMemo(()=>getArrowMenuButtonStyleProps(toggled,sideBarRef,toggledSateValue),[toggled,sideBarRef,toggledSateValue])

  useEffect(()=>setToggledStateValue(false),[location.pathname])

  return <>
    {toggled && <Button className="side_bar_menu_arrow_button" text icon="pi pi-chevron-right" pt={{icon:{className:"side_bar_menu_arrow_icon",...arrowMenuButtonStyleProps?.icon}}} severity="secondary" {...arrowMenuButtonStyleProps?.button} onClick={() => setToggledStateValue((prevValue)=>!prevValue)}></Button>}
    <Sidebar ref={sideBarRef} className="sideBar" collapsed={collapsed} {...getToggledProps(toggled,toggledState)}>
        <div>
            <div className="side_bar_logo_container">
              <Link to={`${initialProjectDirectory}/vacations/listOfRequests`}>
                {collapsed?<img src={shortLogo} className="short_logo" alt="BeUp HR"/>:<img src={logo} className="logo" alt="BeUp HR"/>}
              </Link>
            </div>
            <Menu i18n={i18n} />
            <SettingsMenu model={settingsMenuItems} popup ref={settingsMenuRef}/>
            <div className="side_bar_settings_section">
              <Button icon="pi pi-cog icon" severity="secondary" rounded text onClick={(event) => settingsMenuRef.current.toggle(event)}/>
              <LanguageSwitcher />
            </div>
        </div>
        
          {toggled && <PageFooter/>}
    </Sidebar>
  </>
    
};
