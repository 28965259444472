
import { Controller } from "react-hook-form";
import { Dropdown } from "primereact/dropdown";
import { InputText } from 'primereact/inputtext';
import { Button } from "primereact/button";
import { FieldError } from "../../../../../../shared/FieldError";
import { loadingProps } from "../../../../../../entities/loadingProps";
import { MultiSelect } from 'primereact/multiselect';
import { getMultiSelectSelectedItemsLabelProp } from "../../../../../../shared/lib";
import { isAdditionalDepartmentsField } from "../lib";
import { useMemo, useRef } from "react";

export const ActionEmployee = ({ i18n,formState,actionType,departments,companies,users = [],onSubmit, loading}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    watch
  } = formState;

  const { t } = i18n;							

  const isInitialRender = useRef(true);

  const watchedFieldsValuesArr = watch(["user"])
  const [ userWatchedFieldValue] = watchedFieldsValuesArr

  const additionalDepartmentsFieldVisibility = useMemo(()=>{if(!isInitialRender.current){return isAdditionalDepartmentsField(users,userWatchedFieldValue)}else isInitialRender.current = false},[users,userWatchedFieldValue])

  return (
    <form onSubmit={handleSubmit((fData)=>onSubmit(fData))}>
      <div className="form_fields_grid">
        <label className="align_self_center">{t("repeatedWords.name")} *</label>
        <div className="form_input_container">
          <Controller name="name" control={control} rules={{required:true}} render={({field})=><InputText maxLength={50} placeholder={`${t("repeatedWords.enter")} ${t("repeatedWords.name")}`} value={field.value} onChange={(e) => field.onChange(e.target.value)} />}/>
          <FieldError fieldErr={errors?.name}/>
        </div>

        <label className="align_self_center">{t("repeatedWords.surname")} *</label>
        <div className="form_input_container">
        <Controller name="surname" control={control} rules={{required:true}} render={({field})=><InputText maxLength={50} placeholder={`${t("repeatedWords.enter")} ${t("repeatedWords.surname")}`} value={field.value} onChange={(e) => field.onChange(e.target.value)}/>}/>
        <FieldError fieldErr={errors?.surname}/>
        </div>

        <label className="align_self_center">{t("repeatedWords.company")} *</label>
        <div className="form_input_container">
        <Controller name="company" control={control} rules={{required:true}} render={({field})=><Dropdown placeholder={t("repeatedSentences.placeholder.company")} optionLabel={"name"} optionValue={"@id"} options={companies} value={field.value} onChange={(e) => field.onChange(e.value)}/> }/>
        <FieldError fieldErr={errors?.company}/>
        </div>

        <label className="align_self_center">{t("repeatedWords.department")} *</label>
        <div className="form_input_container">
        <Controller name="department" control={control} rules={{required:true}} render={({field})=><Dropdown placeholder={`${t("repeatedWords.choose")} ${t("repeatedWords.department")}`} optionLabel={"name"} optionValue={"@id"} options={departments} value={field.value} onChange={(e) => field.onChange(e.value)}/> }/>
        <FieldError fieldErr={errors?.department}/>
        </div>

        <label className="align_self_center">{t("repeatedWords.user")}</label>
        <div className="form_input_container">
        <Controller name="user" control={control} render={({field})=><Dropdown placeholder={t("pages.settings.organization.pages.addEmployee.form.addEmployee.fields.user.placeholder")} options={users} optionValue="@id" optionLabel="email" value={field?.value} filter onChange={(e) =>{field.onChange(e.value)}} focusInputRef={field.ref}/> }/>
        <FieldError fieldErr={errors.user}/>
        </div>

        {additionalDepartmentsFieldVisibility && <>
          <label className="align_self_center">{t("repeatedSentences.additionalDepartments")}</label>
          <div className="form_input_container">
            <Controller name="additionalDepartments" control={control} render={({field})=><MultiSelect {...getMultiSelectSelectedItemsLabelProp(field?.value,i18n)} maxSelectedLabels={2} placeholder={`${t("repeatedWords.choose")} ${t("repeatedWords.department")}`} optionLabel={"name"} optionValue={"@id"} options={departments} value={field.value} onChange={(e) => field.onChange(e.value) }/>}/>
            <FieldError fieldErr={errors?.additionalDepartments}/>
          </div>
        </>}
        

        <Button className="submit_button" label={actionType==="update"?t("repeatedWords.update"):t("repeatedWords.add")} {...(loading?loadingProps:{})}/>

      </div>
    </form>
  );
};
