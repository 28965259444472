import { Controller } from "react-hook-form";
import { Password } from 'primereact/password';
import { Button } from "primereact/button";
import { FieldError } from "../../../../shared/FieldError";
import { useEffect } from "react";
import { checkRepeatedPassword, checkNewPassword } from "../lib";

export const ResetPassword = ({ i18n, formState, onSubmit, loading}) => {

  const { handleSubmit, control, formState: { errors }, watch } = formState

  const watchedFieldsValuesArr = watch(["oldPassword","newPassword","repeatPassword"])
  const [ oldPasswordWatchedFieldValue, newPasswordWatchedFieldValue, repeatPasswordWatchedFieldValue] = watchedFieldsValuesArr
  
  useEffect(()=>checkNewPassword(formState,i18n,oldPasswordWatchedFieldValue,newPasswordWatchedFieldValue),[oldPasswordWatchedFieldValue,newPasswordWatchedFieldValue])
  useEffect(()=>checkRepeatedPassword(formState,i18n,newPasswordWatchedFieldValue,repeatPasswordWatchedFieldValue),[newPasswordWatchedFieldValue,repeatPasswordWatchedFieldValue])

  const { t } = i18n;							

  return (
    <form onSubmit={handleSubmit((fData)=>onSubmit(fData))}>
      <div className="form_fields_grid">
        <label className="align_self_center">{t("repeatedSentences.oldPassword")} *</label>
        <div className="form_input_container">
          <Controller name="oldPassword" control={control} rules={{required:true}} render={({field})=><Password maxLength={50} placeholder={`${t("repeatedWords.enter")} ${t("repeatedWords.password")}`} value={field.value} onChange={(e) => field.onChange(e.target.value)} feedback={false} toggleMask />}/>
          <FieldError fieldErr={errors?.oldPassword}/>
        </div>

        <label className="align_self_center">{t("repeatedSentences.newPassword")} *</label>
        <div className="form_input_container">
          <Controller name="newPassword" control={control} rules={{required:true}} render={({field})=><Password maxLength={50} placeholder={`${t("repeatedWords.enter")} ${t("repeatedWords.password")}`} value={field.value} onChange={(e) => field.onChange(e.target.value)} feedback toggleMask />}/>
          <FieldError fieldErr={errors?.newPassword || errors?.customErrorForNewPassword}/>
          
        </div>

        <label className="align_self_center">{t("repeatedSentences.repeatPassword")} *</label>
        <div className="form_input_container">
          <Controller name="repeatPassword" control={control} rules={{required:true}} render={({field})=><Password maxLength={50} placeholder={`${t("repeatedWords.enter")} ${t("repeatedWords.password")}`} value={field.value} onChange={(e) => field.onChange(e.target.value)} feedback={false} toggleMask />}/>
          <FieldError fieldErr={errors?.repeatPassword || errors?.customErrorForRepeatedPassword}/>
        </div>

        <Button className="submit_button" label={t("repeatedWords.reset")} {...(loading?{loading}:{})}/>
      </div>
    </form>
  );
};
