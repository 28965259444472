export const checkDateRangeCompleteness = (formState, i18n, dateRangeFieldValue) =>{

    const { formState: { errors }, setError, clearErrors } = formState

    if(dateRangeFieldValue && !dateRangeFieldValue[1]){
        setError("customErrorForPlannedVacationDatesArr",{type:"DateRangeNotCompleteness",message:i18n.t("error.user.DateRangeNotCompleteness.message")})
        return
    }

   if(errors?.customErrorForPlannedVacationDatesArr)clearErrors("customErrorForPlannedVacationDatesArr")

}