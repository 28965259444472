import { getOptionsGroup } from "../../lib";

export const getOnChangeFunc = (props, optionsState, i18n) => (event) => {
  const { onChange } = props;
  const [, setOptionsStateValue] = optionsState;


  if (event.value) {
    const selectedOptionsGroup = {
      label: i18n.t("repeatedWords.selected"),
      id: "selected",
      items: [
        { label: event.originalEvent.target.innerText, "@id": event.value },
      ],
    };

    setOptionsStateValue((prevState) => {
      const optionsCopy = prevState.options;
      const selectedGroup = getOptionsGroup("selected", optionsCopy);

      if (selectedGroup) {
        optionsCopy[selectedGroup.key] = selectedOptionsGroup;

        return {
          ...prevState,
          selected: selectedOptionsGroup,
          options: optionsCopy,
        };
      }

      return {
        ...prevState,
        selected: selectedOptionsGroup,
        options: [selectedOptionsGroup, ...prevState.options],
      };
    });
  }else{
    setOptionsStateValue(prevState=>{
        const prevStateCopy ={...prevState}
        delete prevStateCopy.selected
        return {...prevStateCopy,options:prevStateCopy.mainView}
    })
  }
  onChange(event);
};
