import { Dropdown } from "primereact/dropdown";
import { OptionSkeleton } from "../../lib";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";

import "./style.css";
import { getFooter } from "../lib";

export const EmployeeDropdown = ({
  i18n,
  optionsState,
  dropDownProps,
  loading,
  pageState,
  searchBarState,
  onChange
}) => {

  const [optionsStateValue] = optionsState
  const [searchBarStateValue,setSearchBarStateValue] = searchBarState

  const loadingTemplate = (options) => (
    <OptionSkeleton loadingOptions={options} />
  );

  const filterTemplate = () => <>
    <div className="p-input-icon-right">
      <i className="pi pi-search" />
      <InputText keyfilter={/^[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ]*$/} maxLength={50} value={searchBarStateValue.value} onChange={e=>setSearchBarStateValue({value:e.target.value})} />
    </div>
    <small>{i18n.t("employeeDropdown.filter.helpText")}</small>
    </>

  const panelFooterTemplate = (
    <Button
      label={i18n.t("repeatedWords.more")}
      severity="info"
      text
      onClick={() =>
        setPageStateValue((prevState) => ({
          ...prevState,
          page: prevState.page + 1,
        }))
      }
      size="small"
    />
  );

  const [pageStateValue, setPageStateValue] = pageState;

  return (
    <Dropdown
      placeholder={i18n.t("repeatedSentences.placeholder.employee")}
      {...dropDownProps}
      {...onChange?{onChange}:{}}
      panelClassName="EmployeeDropdown_panel"
      optionGroupLabel="label"
      optionGroupChildren="items"
      optionValue="@id"
      options={optionsStateValue.options}
      filter
      filterTemplate={filterTemplate}
      {...getFooter(
        searchBarStateValue.value,
        pageStateValue?.totalItems,
        optionsStateValue?.mainView && optionsStateValue?.mainView[1]?.items?.length,
        panelFooterTemplate
      )}
      />
  );
};
