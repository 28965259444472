import { numberOfRowsPageLimit } from "../../../entities/numberOfRowsPageLimit";
import { getFirstRowOfTablePageId } from "../getFirstRowOfTablePageId";

export const getPaginatorConnection = (pageState) => {

    const props ={};
    
    if(pageState){
        const [pageStateValue, setPageStateValue] = pageState;

        props.paginator = true
        props.totalRecords = pageStateValue?.totalItems
        props.rows = numberOfRowsPageLimit
        props.first = getFirstRowOfTablePageId(numberOfRowsPageLimit,pageStateValue.page)
        props.onPage = (e) => setPageStateValue((prevState) => ({ ...prevState, page: e.page + 1 }))
    }

  return props;
};
