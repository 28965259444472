export const EnglandFlag = () => (
  <svg
    version="1.1"
    id="Layer_1"
    width="25px"
    height="25px"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
  >
    <circle cx="256" cy="256" r="256" style={{ fill: "#F0F0F0" }} />
    <path
      style={{ fill: "#D80027" }}
      d="M509.833,222.609h-220.44h-0.001V2.167C278.461,0.744,267.317,0,256,0s-22.461,0.744-33.391,2.167
    v220.44v0.001H2.167C0.744,233.539,0,244.681,0,256c0,11.319,0.744,22.461,2.167,33.391h220.44h0.001v220.442
    C233.539,511.256,244.683,512,256,512s22.461-0.743,33.391-2.167v-220.44v-0.001h220.442C511.256,278.461,512,267.319,512,256
    C512,244.681,511.256,233.539,509.833,222.609z"
    />
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
);
