import { useTranslation } from "react-i18next";
import "./style.css";

export const FieldError = ({
  fieldErr,
  validationConfig = {},
  errPatternMessage = "Pattern Error",
}) => {
  const { t } = useTranslation();

  const getMessage = () => {
    if (!fieldErr) return "";

    const { type, message } = fieldErr;

    if (message) return message;

    switch (type) {
      case "required":
        return t("fieldsValidation.required");
      case "minLength":
        return `${t("fieldsValidation.minLength")} ${validationConfig?.minLength}`;
      case "pattern":
        return errPatternMessage;
      default:
        return "Unknown error type";
    }
  };

  return <span className="fieldError">{getMessage()}</span>;
};
