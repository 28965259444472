import { toastMessageConfig } from "../../../../../../../entities/toastMessageConfig";
import { api } from "../../../../../../../shared/api";
import { getEntityIdByIri } from "../../../../../../../shared/api/lib/getEntityIdByIri";

export const getElectedEmployee = async (
  toastRef,
  componentState,
  employeeIri
) => {
  const [, setComponentState] = componentState;

  setComponentState({ status: "loading" });
    
  const employeeId = getEntityIdByIri(employeeIri);

  const employeeResponse = await api.employees.getById(employeeId);
  const { warning, error } = employeeResponse;

  if (warning) {
    toastRef.current.show({
      severity: "warn",
      summary: warning.title,
      detail: warning.message,
      life: toastMessageConfig.life,
    });
    setComponentState({status: "waiting"});
    return;
  }

  if (error) {
    toastRef.current.show({
      severity: "error",
      summary: error.title,
      detail: error.message,
      sticky: true,
    });
    setComponentState({status: "waiting"});
    return;
  }

  setComponentState({
    status: "waiting",
    data: { electedEmployee: employeeResponse },
  });
};
