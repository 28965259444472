export const getToggledProps = (toggled, toggledState) => {

  if (toggled) {

    const [toggledSateValue, setToggledStateValue] = toggledState;

    return {
      toggled: toggledSateValue,
      onBackdropClick: () => setToggledStateValue(false),
      breakPoint: "all",
    };
  }

  return {};
};
