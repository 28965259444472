export const checkNewPassword = (formState, i18n, oldP, newP) => {
    const { formState: { errors }, setError, clearErrors } = formState

    if(oldP === newP){
        setError("customErrorForNewPassword",{type:"fieldsAreSame",message:i18n.t("repeatedSentences.currentPasswordEntered")})
        return
    }

   if(errors?.customErrorForNewPassword)clearErrors("customErrorForNewPassword")
};
