export const getUserDepartmentsArr = (currentUser) =>{
    let arr = []

    if (currentUser?.employee) {
        const { employee } = currentUser
        
        arr.push(employee.department)

        if(employee?.employeeExtendedAccesses){
            employee.employeeExtendedAccesses.forEach(employeeExtendedAccess => {arr.push(employeeExtendedAccess.department)});
        }
      }

    return arr
}