import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "primereact/resources/primereact.min.css";
import "./theme.css";
import "./entities/i18n";
import { App } from "./app/App";
import { addLocale,locale } from "primereact/api";
import { primeReactPlLocale } from "./entities/primeReactPlLocale";
import "primeicons/primeicons.css";
import 'moment/locale/pl';
import moment from 'moment';

addLocale("pl", primeReactPlLocale.pl);
locale("pl")
moment.locale('pl')

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<BrowserRouter><App/></BrowserRouter>);
