export const getFooter = (
  searchBarValue,
  totalOptionsCount,
  optionsCount,
  panelFooterTemplate
) => {
    if(optionsCount && totalOptionsCount){
        if (searchBarValue === "" && optionsCount < totalOptionsCount)
        return { panelFooterTemplate };
    }
    return {}
};