import { useEffect, useState } from "react";
import { ActionUser as UI } from "./ui/ActionUser";
import { api } from "../../../../../shared/api";
import { toastMessageConfig } from "../../../../../entities/toastMessageConfig";
import { removeEmptyKeys } from "../../../../../shared/api/lib/removeEmptyKeys";
import { useForm } from "react-hook-form";
import { getElectedEmployeeUseEffectFunc, initValues } from "./lib";
import { useRef } from "react";

export const ActionUser = ({
  currentUser,
  tableState = [],
  i18n,
  toastRef,
  dialogState,
  defaultValues,
  actionType = "add",
}) => {
  const componentState = useState({ status: "waiting" });
  const [componentStateValue, setComponentStateValue] = componentState;

  const isInitialRender = useRef(true);

  const [dialogStateValue, setDialogStateValue] = dialogState;
  const formState = useForm({
    mode: "onChange",
    defaultValues: defaultValues ? defaultValues : initValues,
  });

  const { watch } = formState;
  const [electedEmployeeIri] = watch(["employee"]);

  const { t } = i18n;

  useEffect(() => {
    if (!isInitialRender.current && electedEmployeeIri) {
      getElectedEmployeeUseEffectFunc(
        toastRef,
        componentState,
        electedEmployeeIri
      );
    } else isInitialRender.current = false;
  }, [electedEmployeeIri]);

  const onSubmit = async (fData) => {
    setComponentStateValue({ status: "loading" });

    removeEmptyKeys(fData);

    fData.roles = [fData.roles];

    const postUserResponse = await (actionType === "add"
      ? api.users.post(fData)
      : api.users.put(fData.id, fData));
    setDialogStateValue({ visibility: false });

    const { warning, error } = postUserResponse;

    if (warning) {
      toastRef.current.show({
        severity: "warn",
        summary: warning.title,
        detail: warning.message,
        life: toastMessageConfig.life,
      });
      return;
    }

    if (error) {
      toastRef.current.show({
        severity: "error",
        summary: error.title,
        detail: error.message,
        sticky: true,
      });
      return;
    }
    const [,setTableStateValue] = tableState
    setTableStateValue(prevValue=>({...prevValue,refresh:true}))

    toastRef.current.show({
      severity: "success",
      summary: t("repeatedWords.success"),
      detail: t("message.successfulOperation.message"),
      life: toastMessageConfig.life,
    });
  };

  if (componentStateValue.status === "loading")
    return (
      <UI
        formState={formState}
        currentUser={currentUser}
        i18n={i18n}
        toastRef={toastRef}
        actionType={actionType}
        loading
      />
    );
  if (componentStateValue.status === "waiting")
    return (
      <UI
        formState={formState}
        currentUser={currentUser}
        i18n={i18n}
        toastRef={toastRef}
        onSubmit={onSubmit}
        electedEmployee={componentStateValue?.data?.electedEmployee}
        actionType={actionType}
      />
    );
};
