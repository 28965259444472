export const unActive = (row, columnId, additionalBodyFunc) => {
    if (row?.unActive) {
      return (
        <div className="p-disabled">
          {additionalBodyFunc ? additionalBodyFunc(row) : row[columnId]}
        </div>
      );
    }
  
    return additionalBodyFunc ? additionalBodyFunc(row) : row[columnId];
  };
  