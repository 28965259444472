import { useState } from "react";
import { ExportOptima as UI } from "./ui/ExportOptima";
import { api, getDateToSend } from "../../../shared/api";
import { useForm } from "react-hook-form";
import { defaultValues } from "./lib";

export const ExportOptima = ({ i18n, companies }) => {

  const [componentStateValue] = useState({ status: "waiting" });

  const formState = useForm({ mode: "onChange", defaultValues});


  const onSubmit = async (fData) =>{
    const { company:companyId, dateRange } = fData

    document.location.href = api.vacations.getOptimaExtractURLString(companyId,{dateFrom:getDateToSend(dateRange[0]),dateTo:getDateToSend(dateRange[1])})

  }


  if (componentStateValue.status === "loading") return <UI i18n={i18n} formState={formState} onSubmit={onSubmit} companies={companies} loading/>

  if (componentStateValue.status === "waiting") return  <UI i18n={i18n} formState={formState} onSubmit={onSubmit} companies={companies}/>

};
