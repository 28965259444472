import "./style.css"
import { Controller } from "react-hook-form"
import { Dropdown } from "primereact/dropdown"
import { Calendar } from "primereact/calendar"
import { InputText } from "primereact/inputtext"
import { InputTextarea } from "primereact/inputtextarea"
import { Button } from "primereact/button"
import { checkDateRangeCompleteness, getDaysBetweenDates, getTypeOptions } from "../lib"
import { FieldError } from "../../../../shared/FieldError"
import { loadingProps } from "../../../../entities/loadingProps"
import { useEffect, useMemo } from "react"
import { getFieldsConfigByAccess } from "../lib/getFieldsConfigByAccess"
import { EmployeeDropdown } from "../../../../shared/EmployeeDropdown"
import { getCross, getDateObjectsArr, parseSelectValue } from "../../../../shared/lib"

export const ActionVacationRequest = ({
  formState,
  i18n,
  toastRef,
  currentUser,
  className,
  type = "new",
  onSubmit,
  electedEmployee,
  loading = false,
  holidayDates,
}) => {
  const {
    watch,
    handleSubmit,
    formState: { errors, defaultValues },
    control,
    setValue,
  } = formState

  const { t } = i18n

  const fieldsConfigByAccess = useMemo(
    () => getFieldsConfigByAccess({ actionType: type, currentUser, defaultValues }),
    []
  )

  const {
    employee: employeeFieldConfig,
    type: typeFieldConfig,
    plannedVacationDatesArr: plannedVacationDatesArrFieldConfig,
    replacement: replacementFieldConfig,
    comment: commentFieldConfig,
  } = fieldsConfigByAccess

  useEffect(() => {
    if (employeeFieldConfig && employeeFieldConfig?.value) setValue("employee", employeeFieldConfig.value)
  }, [])

  const typesOfRequestOptions = useMemo(() => getTypeOptions(electedEmployee, currentUser), [electedEmployee])

  const calendarDisabledDates = useMemo(() => (holidayDates ? getDateObjectsArr(holidayDates) : undefined), [])

  const plannedVacationDatesArrFieldValue = watch("plannedVacationDatesArr")
  const daysBetweenVacationDates = useMemo(
    () => getDaysBetweenDates(plannedVacationDatesArrFieldValue,false,holidayDates),
    [plannedVacationDatesArrFieldValue]
  )

  useEffect(
    () => checkDateRangeCompleteness(formState, i18n, plannedVacationDatesArrFieldValue),
    [plannedVacationDatesArrFieldValue]
  )

  return (
    <form
      className={className ? `NewVacationRequestForm ${className}` : "NewVacationRequestForm"}
      onSubmit={handleSubmit((fData) => onSubmit(fData))}
    >
      <h3 className="NewVacationRequestForm_title">
        {type === "update"
          ? t("pages.vacations.createRequest.NewVacationRequestForm.updateTitle")
          : t("pages.vacations.createRequest.NewVacationRequestForm.title")}
      </h3>

      <div className="form_fields_grid">
        <label className="align_self_center">
          {t("pages.vacations.createRequest.NewVacationRequestForm.fields.nameAndSurname.label")}
        </label>
        <div className="form_input_container">
          <Controller
            name="employee"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <EmployeeDropdown
                toastRef={toastRef}
                i18n={i18n}
                currentUser={currentUser}
                dropDownProps={{
                  ...(employeeFieldConfig ? employeeFieldConfig : {}),
                  value: field?.value,
                  onChange: (e) => field.onChange(e.value),
                  focusInputRef: field.ref,
                }}
              />
            )}
          />
          <FieldError fieldErr={errors.employee} />
        </div>

        <label>{t("pages.vacations.createRequest.NewVacationRequestForm.fields.positionDepartment.label")}</label>
        <p className="beUpColor">{electedEmployee && electedEmployee.department.name}</p>

        <label className="align_self_center">
          {t("pages.vacations.createRequest.NewVacationRequestForm.fields.typeOfRequest.label")}
        </label>
        <div className="form_input_container">
          <Controller
            name="type"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Dropdown
                {...(typeFieldConfig ? typeFieldConfig : {})}
                placeholder={`${t("repeatedWords.choose")} ${t(
                  "pages.vacations.createRequest.NewVacationRequestForm.fields.typeOfRequest.label"
                )}`}
                optionLabel={"name"}
                optionValue={"@id"}
                options={typesOfRequestOptions}
                value={field.value}
                focusInputRef={field.ref}
                onChange={(e) => field.onChange(e.value)}
              />
            )}
          />
          <FieldError fieldErr={errors.type} />
        </div>

        <label className="align_self_center">
          {t("pages.vacations.createRequest.NewVacationRequestForm.fields.plannedVacation.label")}
        </label>
        <div className="form_input_container">
          <Controller
            name="plannedVacationDatesArr"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Calendar
                value={field.value}
                onChange={(e) => field.onChange(e.value)}
                selectionMode="range"
                disabledDates={calendarDisabledDates}
                readOnlyInput
                showIcon
                showButtonBar
                placeholder={t(
                  "pages.vacations.createRequest.NewVacationRequestForm.fields.plannedVacation.placeholder"
                )}
                {...(plannedVacationDatesArrFieldConfig ? plannedVacationDatesArrFieldConfig : {})}
              />
            )}
          />
          <FieldError fieldErr={errors.customErrorForPlannedVacationDatesArr} />
          <FieldError fieldErr={errors.plannedVacationDatesArr} />
        </div>

        <label className="align_self_center">
          {t("pages.vacations.createRequest.NewVacationRequestForm.fields.vacationDaysNumber.label")}
        </label>
        <InputText
          value={daysBetweenVacationDates}
          className="NewVacationRequestForm_input_vacationDaysNumber beUpColor"
          disabled
        />

        <label className="align_self_center">
          {t("pages.vacations.createRequest.NewVacationRequestForm.fields.replacement.label")}
        </label>
        <div className="form_input_container">
          <Controller
            name="replacement"
            control={control}
            render={({ field }) => (
              <EmployeeDropdown
                toastRef={toastRef}
                i18n={i18n}
                currentUser={currentUser}
                dropDownProps={{
                  ...(replacementFieldConfig ? replacementFieldConfig : {}),
                  value: field?.value,
                  onChange: (e) => field.onChange(parseSelectValue(e.value)),
                  ...getCross(field?.value),
                }}
              />
            )}
          />
          <FieldError fieldErr={errors.replacement} />
        </div>

        <label>{t("pages.vacations.createRequest.NewVacationRequestForm.fields.comment.label")}</label>
        <Controller
          name="comment"
          control={control}
          render={({ field }) => (
            <InputTextarea
              {...(commentFieldConfig ? commentFieldConfig : {})}
              {...field}
              autoResize
              rows={5}
              cols={30}
              maxLength={200}
              placeholder={t("pages.vacations.createRequest.NewVacationRequestForm.fields.comment.placeholder")}
            />
          )}
        />

        <Button
          className="submit_button"
          label={
            type === "update"
              ? t("repeatedWords.update")
              : t("pages.vacations.createRequest.NewVacationRequestForm.submitButton")
          }
          {...(loading ? loadingProps : {})}
        />
      </div>
    </form>
  )
}
