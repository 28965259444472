import { Error } from "../../../shared/Error";
import { useTranslation } from "react-i18next";

export const NotFound = () => {
  const { t } = useTranslation();
  return (
    <Error
      error={{
        title: t("error.user.pageNotFound.title"),
        message: t("error.user.pageNotFound.message"),
      }}
    />
  );
};
