import { useTranslation } from "react-i18next";
import "./style.css";
import { Dropdown } from "primereact/dropdown";
import { countryOptionTemplate } from "./lib";
import { locale } from "primereact/api";
import moment from 'moment';

export const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  const options = [
    { label: "Polski", value: "pl" },
    { label: "English", value: "en"},
  ];

  return (
    <Dropdown
      value={i18n.resolvedLanguage}
      onChange={(e) => {
        i18n.changeLanguage(e.value)
        locale(e.value);
        moment.locale(e.value)
      }}
      options={options}
      placeholder="Select a City"
      className="language_switcher"
      valueTemplate={countryOptionTemplate}
      itemTemplate={countryOptionTemplate}
    />
  );
};
