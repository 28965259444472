export const Delegations = () => (
  <svg
    version="1.1"
    id="_x32_"
    xmlns="http://www.w3.org/2000/svg"
    className="icon"
    viewBox="0 0 512 512"
  >
    <g>
      <path
        className="st0"
        d="M291.789,390.688L279.727,380.5c-8.844,10.531-13.203,22.906-15.344,34.125l15.5,2.938
		C281.68,408.125,285.289,398.375,291.789,390.688z"
      />
      <path
        className="st0"
        d="M333.648,366.781l-9.297-12.75c-4.281,3.125-9.484,5.781-15.875,7.781c-5.141,1.594-9.781,3.656-13.938,6.125
		l8.078,13.563c3.047-1.813,6.531-3.391,10.516-4.625C321.07,374.438,327.898,370.969,333.648,366.781z"
      />
      <path
        className="st0"
        d="M343.242,316.438c-0.406,9.297-3.031,19.219-8.891,27.469L347.242,353
		c7.969-11.313,11.25-24.188,11.766-35.906L343.242,316.438z"
      />
      <path
        className="st0"
        d="M167.398,424.656c-2.313-12.188-7.219-22.719-13.453-31.625l-12.922,9.047v0.016
		c5.125,7.313,9,15.688,10.875,25.5L167.398,424.656z"
      />
      <path
        className="st0"
        d="M116.148,360.203l-7.703,13.766c7.797,4.375,16.141,10.094,23.391,17.281l11.109-11.219
		C134.367,371.563,124.883,365.094,116.148,360.203z"
      />
      <path
        className="st0"
        d="M511.805,477.844l-8.703-19.125L412.555,259.25H286.289c-1.703,3-3.25,5.844-5.047,8.906
		c-4.859,8.313-9.359,15.844-13.531,22.656h69.859l-0.125,0.125c1.172,1.125,3.344,5.219,4.516,10.594l15.375-3.5
		c-0.609-2.563-1.359-4.938-2.203-7.219h37.094l72.016,158.641H265.758l13.203-2.047l-0.016-0.125
		c-0.109-0.844-0.703-5.344-0.703-11.719c0-0.813,0.016-1.641,0.031-2.5l-15.766-0.359c-0.031,0.984-0.031,1.922-0.031,2.859
		c0,7.297,0.656,12.5,0.844,13.891h-94.906c0.25-2.578,0.422-5.109,0.453-7.578l-15.781-0.219c-0.031,2.5-0.234,5.125-0.531,7.797
		H47.758l38.719-85.297c2.313,0.781,5.266,1.875,8.688,3.313l6.172-14.5c-3.156-1.344-5.938-2.406-8.313-3.25l26.75-58.906h38.844
		c-4.156-6.813-8.656-14.344-13.531-22.656c-1.797-3.063-3.344-5.906-5.047-8.906H99.43L0.195,477.844
		c-0.313,0.703-0.25,1.5,0.156,2.156c0.422,0.625,1.125,1,1.891,1h507.516c0.766,0,1.469-0.375,1.891-1
		C512.055,479.344,512.117,478.547,511.805,477.844z"
      />
      <path
        className="st0"
        d="M213.164,340.656c5,0,105.156-146.406,105.156-204.5C318.32,78.094,271.242,31,213.164,31
		c-58.094,0-105.172,47.094-105.172,105.156C107.992,194.25,208.164,340.656,213.164,340.656z M172.945,136.156
		c0-22.219,18.016-40.219,40.219-40.219c22.219,0,40.234,18,40.234,40.219s-18.016,40.234-40.234,40.234
		C190.961,176.391,172.945,158.375,172.945,136.156z"
      />
    </g>
  </svg>
);
