import { getElementByIriFromArr } from "../../../../../../shared/lib";

export const isAdditionalDepartmentsField = (users,electedUser) =>{
    
    if(electedUser){
        const userObject = getElementByIriFromArr(electedUser,users)
        if(userObject && userObject.roles.includes("ROLE_MOD"))return true
    }

    return false
}