import "./style.css";
import { WarningIcon } from "./svg";

export const Error = ({
  error = { code: "333", title: "title", message: "message" },
  Icon,
  className,
}) => {
  let { code, title, message, linkConfig } = error;

  if (!code) code = 400;
  if (!message) message = "Oops, something went wrong...";

  return (
    <div
      {...(className
        ? { className: "error " + className }
        : { className: "error" })}
    >
      {Icon ? <Icon secondClassName="warningIcon" /> : <WarningIcon />}
      <div>
        <h2 className="error_title">{title}</h2>
        <p>{message}</p>
        {linkConfig && <a href={linkConfig.link}>{linkConfig.title}</a>}
      </div>
    </div>
  );
};
