import { itemsPerPageForAllItems } from "../../../../entities/itemsPerPageForAllItems";
import { toastMessageConfig } from "../../../../entities/toastMessageConfig";
import { api } from "../../../api";
import { getPersonsOptionLabel } from "../../../lib";

export const search = async (i18n, filterDefaultValues, toastRef, states) => {
  const { componentState, optionsState, searchBarState } = states;

  const [, setComponentStateValue] = componentState;
  const [, setOptionsStateValue] = optionsState;
  const [searchBarStateValue] = searchBarState;

    if(searchBarStateValue.value === ""){
        setOptionsStateValue(prevState=>{

          const {selected,mainView} =  prevState

          if(selected){
            return {...prevState,options:[selected,...mainView]}
          }

          return {...prevState,options:prevState.mainView}
        })
        return
    }

    setComponentStateValue({ status: "loading" });

    const employeesResponse = await api.employees.get({...filterDefaultValues, itemsPerPage:itemsPerPageForAllItems,surname:searchBarStateValue.value});

    const { error, warning } = employeesResponse;

    if (warning) {
      toastRef.current.show({
        severity: "warn",
        summary: warning.title,
        detail: warning.message,
        life: toastMessageConfig.life,
      });
      return;
    }

    if (error) {
      toastRef.current.show({
        severity: "error",
        summary: error.title,
        detail: error.message,
        sticky: true,
      });
      return;
    }

    setComponentStateValue({ status: "success" });

    const employees = employeesResponse["hydra:member"];
    getPersonsOptionLabel(employees);

    if(employees.length){
        setOptionsStateValue(prevState=>{
          const {selected} =  prevState

          if(selected){
           return {...prevState,options:[selected,{label: i18n.t("repeatedWords.all"),items: [...employees]}]}
          }
          return {...prevState,options:[{label: i18n.t("repeatedWords.all"),items: [...employees]}]}
        });
    }else setOptionsStateValue(prevState=>({...prevState,options:[]}))

    
};
