
import { Controller } from "react-hook-form";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from 'primereact/inputnumber';
import { Button } from "primereact/button";
import { FieldError } from "../../../../../../../../shared/FieldError";
import { loadingProps } from "../../../../../../../../entities/loadingProps";
import { useMemo } from "react";
import { isUnusedDaysFromPreviousYearFieldDisabled } from "../lib";

export const Action = ({ i18n,formState,actionType,vacationTypes,onSubmit,loading}) => {
  const { handleSubmit, control, formState: { errors }, watch} = formState;
  const { t } = i18n;							

  
  const watchedFieldsValuesArr = watch(["vacationType"])
  const [vacationTypeWatchedFieldValue] = watchedFieldsValuesArr

  const unusedDaysFromPreviousYearFieldDisabledStatus = useMemo(()=>isUnusedDaysFromPreviousYearFieldDisabled(vacationTypeWatchedFieldValue),[vacationTypeWatchedFieldValue])

  return (
    <form onSubmit={handleSubmit((fData)=>onSubmit(fData))}>
      <div className="form_fields_grid">

        <label className="align_self_center">{t("pages.vacations.createRequest.NewVacationRequestForm.fields.typeOfRequest.label")} * </label>
        <div className="form_input_container">
        <Controller name="vacationType" control={control} rules={{required:true}} render={({field})=><Dropdown disabled placeholder={`${t("repeatedWords.choose")} ${t("pages.vacations.createRequest.NewVacationRequestForm.fields.typeOfRequest.label")}`} optionLabel={"name"} optionValue={"@id"} options={vacationTypes} value={field.value}  focusInputRef={field.ref} onChange={(e) => field.onChange(e.value)}/> }/>
        <FieldError fieldErr={errors.vacationType}/>
        </div>

        <label className="align_self_center">{t("repeatedSentences.daysNumber")} *</label>
        <div className="form_input_container">
        <Controller name="daysLimit" control={control} rules={{required:true}} render={({field})=><InputNumber placeholder={t("repeatedSentences.daysNumber")} mode="decimal" showButtons min={0} max={1200} maxLength={4} value={field.value} onValueChange={(e) => field.onChange(e)} useGrouping={false}/>}/>
        <FieldError fieldErr={errors?.daysLimit}/>
        </div>
        
        <label className="align_self_center">{t("repeatedSentences.previousYearDaysNumber")}</label>
        <div className="form_input_container">
        <Controller name="unusedDaysFromPreviousYear" control={control} render={({field})=><InputNumber {...unusedDaysFromPreviousYearFieldDisabledStatus?{disabled:true}:{}} placeholder={t("repeatedSentences.daysNumber")} mode="decimal" showButtons min={1} max={1200} maxLength={4} value={field.value} onValueChange={(e) => field.onChange(e)} useGrouping={false}/>}/>
        <FieldError fieldErr={errors?.unusedDaysFromPreviousYear}/>
        </div>

        <Button className="submit_button" label={actionType==="update"?t("repeatedWords.update"):t("repeatedWords.add")} {...(loading?loadingProps:{})}/>

      </div>
    </form>
  );
};
