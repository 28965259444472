import { useRef, useState } from "react";
import { EmployeeDropdown as UI } from "./ui/EmployeeDropdown";
import { useEffect } from "react";
import { defaultPageStateConfig, firstRenderUseEffectFunc, getFilterDefaultValues, getOnChangeFunc, loadMoreUseEffectFunc, searchUseEffectFunc } from "./lib";
import { useMemo } from "react";

export const EmployeeDropdown = ({
  toastRef,
  i18n,
  currentUser,
  dropDownProps = {},
  filterProp
}) => {
  const isInitialRender = useRef(true);

  const componentState = useState({status: "loading"});
  const [componentStateValue] = componentState

  const optionsState =  useState({options:[]})

  const filterDefaultValues = useMemo(()=>getFilterDefaultValues(filterProp),[])

  const pageState = useState(defaultPageStateConfig);
  const [pageStateValue] = pageState;

  const searchBarState = useState({value:""})
  const [searchBarStateValue,setSearchBarStateValue] = searchBarState

  const onChange = useMemo(()=>dropDownProps?.onChange?getOnChangeFunc(dropDownProps,optionsState,i18n):undefined,[])

  useEffect(() => {
    (async () => await firstRenderUseEffectFunc(i18n,currentUser,filterDefaultValues,toastRef,{componentState,optionsState,pageState},dropDownProps))();
  }, []);

  useEffect(() => {
    if (!isInitialRender.current) {
      (async () => {await loadMoreUseEffectFunc(i18n,filterDefaultValues,toastRef,{componentState,optionsState,pageState})})();
    }
  }, [pageStateValue.page]);

  useEffect(() => {
    if (!isInitialRender.current) {
      const delay = setTimeout(()=>{
        (async () => {await searchUseEffectFunc(i18n,filterDefaultValues,toastRef,{componentState,optionsState,searchBarState})})();
      },500)
      
      return () => clearTimeout(delay)
    }
  }, [searchBarStateValue.value]);

  useEffect(()=>{isInitialRender.current = false},[])

  if (componentStateValue.status === "loading")
    return (
      <UI
        i18n={i18n}
        optionsState={optionsState}
        dropDownProps={dropDownProps}
        loading={true}
        pageState={pageState}
        searchBarState={searchBarState}
        onChange={onChange}
      />
    );

  return (
    <UI
      i18n={i18n}
      optionsState={optionsState}
      dropDownProps={dropDownProps}
      pageState={pageState}
      searchBarState={searchBarState}
      onChange={onChange}
    />
  );
};