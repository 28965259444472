import { useState } from "react"
import { ActionHolidayDate as UI } from "./ui/ActionHolidayDate"
import { api, getDateToSend } from "../../../../../shared/api"
import { useForm } from "react-hook-form"
import { defaultValues } from "./lib"
import { toastMessageConfig } from "../../../../../entities/toastMessageConfig"

export const ActionHolidayDate = ({ i18n, toastRef, dialogState, tableState }) => {
  const [componentStateValue, setComponentStateValue] = useState({ status: "waiting" })

  const formState = useForm({ mode: "onChange", defaultValues })

  const onSubmit = async (fData) => {
    setComponentStateValue({ status: "loading" })

    fData.date = getDateToSend(new Date(fData.date));

    const actionHolidayDateResponse = await api.holidayDates.post(fData)

    const [, setDialogStateValue] = dialogState

    setDialogStateValue({ visibility: false })

    const { warning, error } = actionHolidayDateResponse

    if (warning) {
      toastRef.current.show({
        severity: "warn",
        summary: warning.title,
        detail: warning.message,
        life: toastMessageConfig.life,
      })
      return
    }

    if (error) {
      toastRef.current.show({ severity: "error", summary: error.title, detail: error.message, sticky: true })
      return
    }

    toastRef.current.show({
      severity: "success",
      summary: i18n.t("repeatedWords.success"),
      detail: i18n.t("message.successfulOperation.message"),
      life: toastMessageConfig.life,
    })

    const [, setTableStateValue] = tableState
    setTableStateValue((prevState) => ({ ...prevState, refreshesNumber: prevState.refreshesNumber + 1 }))
  }

  if (componentStateValue.status === "loading")
    return <UI i18n={i18n} formState={formState} onSubmit={onSubmit} loading />

  if (componentStateValue.status === "waiting") return <UI i18n={i18n} formState={formState} onSubmit={onSubmit} />
}
