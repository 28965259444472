export const checkRepeatedPassword = (formState, i18n, newP, repeatedP) => {
    const { formState: { errors }, setError, clearErrors } = formState

    if(newP !== repeatedP){
        setError("customErrorForRepeatedPassword",{type:"fieldsDontMatch",message:i18n.t("repeatedSentences.fieldsDontMatch")})
        return
    }

   if(errors?.customErrorForRepeatedPassword)clearErrors("customErrorForRepeatedPassword")
};
