import { authTokenName } from "../../../../../entities/authTokenName";

export const parseError = (err) => {
  const { data, status } = err?.response || {};

  if (status === 401 && !err.config.url.includes("/login")){
    sessionStorage.removeItem(authTokenName)
    document.location.reload();
  }

  if (!data || !data["hydra:description"]) {
    const { code, message } = err;
    return { warning: { title: code, message } };
  }

  const errMessage = {title:data["hydra:title"],message:data["hydra:description"]}

  if (errMessage.title || errMessage.message) {

    if (status >= 500) return { error:errMessage };

    if (status >= 400 || status >= 300)
      return { warning: errMessage };
  }

  return {
    error: { title: "An unexpected answer", message: "Try again" },
  };
};
