export const getDateToSend = (dateObject) =>{
    const getZeroByDate = (dateEntity)=>dateEntity<10?"0":""

    const month = dateObject.getMonth() + 1;
    const day = dateObject.getDate();
    const year = dateObject.getFullYear();

    const result = `${year}-${getZeroByDate(month)}${month}-${getZeroByDate(day)}${day}`

    return result
}