export const PolandFlag = () => (
  <svg version="1.1"  x="0px" y="0px" viewBox="0 0 473.677 473.677" width="25px" height="25px">
    <path
      style={{ fill: "#E4E4E4" }}
      d="M324.752,236.842h148.925C473.677,106.032,367.641,0,236.835,0
	C302.232,0,324.76,118.425,324.752,236.842z"
    />
    <path
      style={{ fill: "#F3F4F5" }}
      d="M0,236.842h334.931C334.939,118.425,302.232,0,236.835,0C106.036,0,0,106.032,0,236.842z"
    />
    <path
      style={{ fill: "#D84141" }}
      d="M319.771,236.842c0,118.417-17.535,236.835-82.936,236.835
	c130.807,0,236.842-106.036,236.842-236.835H319.771z"
    />
    <path
      style={{ fill: "#DC4D4E" }}
      d="M334.931,236.842H0c0,130.799,106.036,236.835,236.835,236.835
	C302.232,473.677,334.931,355.26,334.931,236.842z"
    />
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
);
