import { getDateToSend } from "../../../../shared/api"
import { getDateObjectsObj } from "../../../../shared/lib"

export const getDaysBetweenDates = (dates, weekendCounting = false, holidayDatesArr) => {
  let daysNumber = 0

  if (!dates || !dates[1]) return daysNumber

  const startDate = new Date(dates[0])
  const endDate = new Date(dates[1])

  const holidayDatesObj = holidayDatesArr ? getDateObjectsObj(holidayDatesArr) : undefined

  for (let currentDate = startDate; currentDate <= endDate; currentDate.setDate(currentDate.getDate() + 1)) {
    if (!weekendCounting) {
      const weekDayId = currentDate.getDay()
      if (weekDayId === 0 || weekDayId === 6) continue
    }

    if (holidayDatesObj) {
      const dateToSendString = getDateToSend(currentDate)
      if (holidayDatesObj[dateToSendString]) continue
    }

    daysNumber++
  }

  return daysNumber
}
