import { useState } from "react";
import { ResetPassword as UI } from "./ui/ResetPassword";
import { api } from "../../../shared/api";
import { toastMessageConfig } from "../../../entities/toastMessageConfig";
import { useForm } from "react-hook-form";
import { defaultValues } from "./lib";

export const ResetPassword = ({ i18n, toastRef, dialogState}) => {

  const [componentStateValue, setComponentStateValue] = useState({ status: "waiting" });

  const formState = useForm({ mode: "onChange",defaultValues,criteriaMode:"all"});

  const getOnSubmit = (i18n,toastRef)=> async (fData) =>{
  
    const { t } = i18n;	

    const [,setDialogStateValue] = dialogState

    delete fData.repeatPassword 

    setComponentStateValue({ status: "loading" });

    const changePasswordResponse = await api.users.changePassword(fData)
    setDialogStateValue({ visible: false });

    const { warning, error } = changePasswordResponse

    if(warning){
      toastRef.current.show({severity: "warn",summary: warning.title, detail: warning.message});
      setComponentStateValue({ status: "waiting" });
      return
    }

    if(error){
      toastRef.current.show({ severity: 'error', summary: error.title, detail: error.message,sticky:true })
      setComponentStateValue({ status: "waiting" });
      return
    }

    toastRef.current.show({ severity: 'success', summary: t("repeatedWords.success"), detail: t("message.successfulOperation.message"),life:toastMessageConfig.life})
    setComponentStateValue({ status: "waiting" });
  }


  if (componentStateValue.status === "loading") return <UI i18n={i18n} formState={formState} onSubmit={getOnSubmit(i18n,toastRef)} loading/>
  if (componentStateValue.status === "waiting") return <UI i18n={i18n} formState={formState} onSubmit={getOnSubmit(i18n,toastRef)}/>

};
