import { itemsPerPageForAllItems } from "../../../../entities/itemsPerPageForAllItems";
import { toastMessageConfig } from "../../../../entities/toastMessageConfig";
import { api } from "../../../api";
import { getEntityIdByIri } from "../../../api/lib/getEntityIdByIri";
import { getPersonsOptionLabel } from "../../../lib";
import { defaultPageStateConfig } from "../defaultPageStateConfig";

export const firstRender = async (
  i18n,
  currentUser,
  filterDefaultValues,
  toastRef,
  states,
  props
) => {
  const { componentState, optionsState, pageState } = states;

  const [, setComponentStateValue] = componentState;
  const [, setOptionsStateValue] = optionsState;
  const [pageStateValue, setPageStateValue] = pageState;

  let defaultEmployee;

  if (props?.value) {
    const defaultEmployeeResponse = await api.employees.getById(
      getEntityIdByIri(props.value)
    );

    const { error, warning } = defaultEmployeeResponse;

    if (warning) {
      toastRef.current.show({
        severity: "warn",
        summary: warning.title,
        detail: warning.message,
        life: toastMessageConfig.life,
      });
      return;
    }

    if (error) {
      toastRef.current.show({
        severity: "error",
        summary: error.title,
        detail: error.message,
        sticky: true,
      });
      return;
    }

    defaultEmployee = defaultEmployeeResponse;
  }

  let employeesByDepartment;

  if (currentUser?.employee) {
    const employeesByDepartmentResponse = await api.employees.get({
      ...filterDefaultValues,
      itemsPerPage: itemsPerPageForAllItems,
      department: currentUser.employee.department["@id"],
    });

    const { error, warning } = employeesByDepartmentResponse;

    if (warning) {
      toastRef.current.show({
        severity: "warn",
        summary: warning.title,
        detail: warning.message,
        life: toastMessageConfig.life,
      });
      return;
    }

    if (error) {
      toastRef.current.show({
        severity: "error",
        summary: error.title,
        detail: error.message,
        sticky: true,
      });
      return;
    }
    employeesByDepartment = employeesByDepartmentResponse["hydra:member"];
  }

  const employeesResponse = await api.employees.get({
    ...filterDefaultValues,
    page: defaultPageStateConfig.page,
  });

  const { error, warning } = employeesResponse;

  if (warning) {
    toastRef.current.show({
      severity: "warn",
      summary: warning.title,
      detail: warning.message,
      life: toastMessageConfig.life,
    });
    return;
  }

  if (error) {
    toastRef.current.show({
      severity: "error",
      summary: error.title,
      detail: error.message,
      sticky: true,
    });
    return;
  }

  setComponentStateValue({ status: "success" });

  const options = [];

  let selectedGroup;

  if (defaultEmployee) {
    getPersonsOptionLabel([defaultEmployee]);

    selectedGroup = {
      label: i18n.t("repeatedWords.selected"),
      id: "selected",
      items: [{ label: defaultEmployee.label, "@id": defaultEmployee["@id"] }],
    };

    options.push(selectedGroup);
  }

  const mainViewOptions = [];

  if (employeesByDepartment) {
    getPersonsOptionLabel(employeesByDepartment);
    mainViewOptions.push({
      label: i18n.t("repeatedWords.department"),
      items: employeesByDepartment,
    });
  }

  const employees = employeesResponse["hydra:member"];
  getPersonsOptionLabel(employees);
  mainViewOptions.push({
    label: i18n.t("repeatedWords.all"),
    items: employees,
  });

  options.push(...mainViewOptions);

  const optionsStateValue = { mainView: mainViewOptions, options };

  console.log(optionsStateValue);
  if (selectedGroup) optionsStateValue.selected = selectedGroup;

  setOptionsStateValue(optionsStateValue);
  setPageStateValue((prevState) => ({
    ...prevState,
    totalItems: employeesResponse["hydra:totalItems"],
  }));
};
